import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SesionService } from 'src/app/core/services/sesion.service';
import { CookieService } from 'ngx-cookie-service';
import { BrokerService } from 'src/app/core/services/broker.service';
import { DebtsService } from 'src/app/core/services/debts.service';
import { CanActiveViaAuthGuard } from 'src/app/core/services/auth-guard.service';
import { environment } from 'src/environments/environment';
import { DataLayerService } from 'src/app/core/services/data-layer.service';

declare var $: any;

@Component({
	selector: 'app-header-new',
	templateUrl: './header-new.component.html',
	styleUrls: ['./header-new.component.scss']
})
export class HeaderNewComponent implements OnInit {

	url: any;
	logoWhite = 'assets/icons/logo-w_n.svg';
	logoColor = 'assets/icons/logo-l_n.svg';
	public menuAbierto = false;
	loginUrl: string;
	regUrl: any;
	urlBc: string;
	urlMidc:string;
	show_mobile_option = false;
	
	@HostListener('window:resize', ['$event'])
	onResize(event: any){
		this.show_mobile_option = event.target.innerWidth <= 425;
	}

	constructor(
		public sesion: SesionService,
		private cookieService: CookieService,
		private broker: BrokerService,
		public debtsService: DebtsService,
		public guard: CanActiveViaAuthGuard,
		public router: Router,
		private dlService: DataLayerService
	) {
		this.loginUrl = environment.urlLogin;
		this.regUrl = environment.urlRegister;
		this.urlBc = environment.urlBc;
		this.urlMidc = environment.urlMidc;
		this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				this.url = val.url;
			}
		});
		this.show_mobile_option = window.innerWidth <= 768;
	}
	ngOnInit() {
		$("#new-header").hide();
		this.guard.validateSession();
	}

	@HostListener('window:scroll', ['$event']) // for window scroll events
	onScroll(event: any) {
		// this.brandPDA();
	}

	brandPDA() {
		$("#lBrand").attr({alt: "Logo Ponte al Día"});
		switch (this.url) {
			case '/':
				if ($(window).width() >= 768) {
					if (window.scrollY !== 0) {
						$("nav").addClass("bg-primary-pda");
						$(".nav-btn a").removeClass("red-btn");
						return this.logoWhite;
					} else {
						$("nav").removeClass("bg-primary-pda");
						return this.logoColor;
					}
				} else {
					if (window.scrollY !== 0) {
						$("nav").addClass("bg-primary-pda");
						return this.logoWhite;
					} else {
						$("nav").removeClass("bg-primary-pda");
						return this.logoWhite;
					}
				}
				break;
			case '/deudas':
				$("#lBrand").attr({alt: ""});
						$("nav").addClass("bg-primary-pda");
						$(".hamburger").removeClass("red-hamburguer");
						$(".nav-btn a").removeClass("red-btn");
						return this.logoWhite;
				break;
			case '/no-deudas':
				$("#lBrand").attr({alt: ""});
						$("nav").addClass("bg-primary-pda");
						$(".hamburger").removeClass("red-hamburguer");
						$(".nav-btn a").removeClass("red-btn");
						return this.logoWhite;
				break;
			case '/propuesta':
			case '/confirmar':
			case '/imprimir':
			case '/faq':
				if ($(window).width() >= 768) {
					if (window.scrollY !== 0) {
						$("nav").addClass("bg-primary-pda");
						$(".nav-btn a").removeClass("red-btn");
						return this.logoWhite;
					} else {
						$("nav").addClass("bg-primary-pda");
						$(".nav-btn a").removeClass("red-btn");
						return this.logoWhite;
					}
				} else {
					if (window.scrollY !== 0) {
						$("nav").addClass("bg-primary-pda");
						$(".nav-btn a").removeClass("red-btn");
						return this.logoWhite;
					} else {
						$("nav").removeClass("bg-primary-pda");
						$(".nav-btn a").addClass("red-btn");
						return this.logoColor;
					}
				}
				break;
			default:
				if ($(window).width() >= 768) {
					if (window.scrollY !== 0) {
						$("nav").addClass("bg-primary-pda");
						$(".nav-btn a").removeClass("red-btn");
						return this.logoWhite;
					} else {
						$("nav").removeClass("bg-primary-pda");
						return this.logoColor;
					}
				} else {
					if (window.scrollY !== 0) {
						$("nav").addClass("bg-primary-pda");
						return this.logoWhite;
					} else {
						$("nav").removeClass("bg-primary-pda");
						return this.logoWhite;
					}
				}
				break;
		}
	}
	openMenu() {
		// $('.arrow-rotate').toggleClass('rotate0');
		if (!this.menuAbierto) {
			// $("#new-header").show("slow");
			$("#new-header").slideToggle('slow');
			$("#new-header").focus();
			this.menuAbierto = true;
			this.enviarDataLayer('Abrir menú | Pontealdía');
		} else {
			// $("#new-header").hide("slow");
			$("#new-header").slideToggle('fast');
			this.menuAbierto = false;
		}
	}
	irMideudas() {
		$("#new-header").slideToggle('fast');
		this.menuAbierto = false;
		this.enviarDataLayer( 'Obligaciones por pagar' );
		this.router.navigate(['/deudas']);
	}
	cerrarSesion() {
		// $('#serlefinChat').next()[0].remove();
		// $('#serlefinChat').remove();
		$("#new-header").hide()
		const body = {
			clientId: environment.clientId,
			idSession: this.sesion.sesionCookie,
			country: environment.country
		};
		this.cookieService.delete('IDSESSIONMDC', this.sesion.sesionCookie, environment.domain);
		this.broker.cerrar_sesion(body).subscribe((response: any) => {
			window.localStorage.clear();
		}, error => {
		});
		setTimeout(() => {
			this.cookieService.delete('IDSESSIONMDC', this.sesion.sesionCookie, environment.domain);
			this.sesion.sesionCookie = null;
			this.router.navigate(['/']);
			$('#serlefinChat').next()[0].remove();
			$('#serlefinChat').remove();
			// this.router.navigate(['/'], { queryParams: { page: 'out' } });
		}, 500);

		this.enviarDataLayer( 'Cerrar sesión | Pontealdía' );
	}

	enviarDataLayer( btn_text: string ){
		// GA4 - clic_header_menu
		this.dlService.dl_eventoGA4_menu( 'clic_header_menu', btn_text );
	}
}

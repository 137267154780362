import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var googletag: any;

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor() { }

  /**
   * 
   * @param slot ID(Slot) que hace referencia al configurado en google ads
   * @param id ID del div donde se renderiza el banner
   * La funcion valida si el slot exite 
   * -si no exite lo crea, configura y manda a renderizar
   * -si existe lo redespliega
   * tiene un timer por si el div se demora en cargar en cado de que este este dentro de un ngIf o similiar
   */

  addPubliById(slot:string,id: string) {
    setTimeout(()=>{
      googletag.cmd.push(() => {
        var slotAdd = googletag.pubads().getSlots().find((x:any) => x.getSlotId().getId() == slot+"_0");
        if(slotAdd == undefined){
          googletag.defineSlot(slot, [[728, 90], [300, 50]], id).addService(googletag.pubads());
          googletag.pubads().enableSingleRequest();
          googletag.enableServices();
          googletag.display(id);
        }else{
          googletag.pubads().refresh([slotAdd]);
        }
      });
    },100);
  }

}

<div class="container-fluid main">
	<div class="container home">
		<div class="row pt-5">
			<div class="col-md-6 col-12 align-self-center">
				<div class="title">
					<h1>Te ayudamos de manera GRATUITA a negociar con descuentos de hasta el 100% en los intereses</h1>
					<hr>
					<a *ngIf="!sesion.sesionCookie" class="btn-pda btn-orange w-60-btn h-lg-btn" id="startPDA"
						(click)="enviarDataLayer('Consultar tus deudas', 'login')">
						<span>Consultar tus deudas</span>
						<i class="fa fa-chevron-right" aria-hidden="true"></i>
					</a>
					<button *ngIf="sesion.sesionCookie" class="btn-pda btn-orange w-60-btn h-lg-btn" id="startPDA1"
						data-toggle="modal" data-target="#modal-info">
						<span>Consultar tus deudas - con sesión</span>
						<i class="fa fa-chevron-right" aria-hidden="true"></i>
					</button>
				</div>
			</div>
			<div class="col-md-6 col-12 img-header text-right pt-5 d-none d-md-block">
				<img src="assets/images/all/img_header_home.svg" alt="Ponte al Día" />
			</div>
		</div>
	</div>
</div>
<div class="container-fluid">
	<div class="container steps">
		<div class="row">
			<div class="col">
				<div id='div-gpt-ad-1683752952200-0' class="commercial-banner" [ngClass]="checkBannerOfertas()">
					<!-- <script>
					//   googletag.cmd.push(function() { googletag.display('div-gpt-ad-1674167641299-0'); });
					alert("hola");
					</script> -->
				</div>
				<div class="title-sec">
					<h2>¿Cómo funciona Ponte al día?</h2>
					<hr>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-4 item">
				<img src="assets/images/home/consultar_obliogaciones.svg" alt="Consultar obligaciones"
					title="Consultar obligaciones" />
				<div class="sub-title">
					<h3>Consulta <br>tus obligaciones</h3>
				</div>
				<p>Inicia sesión o regístrate para consultar las obligaciones en mora que tienes disponibles para
					negociar.</p>
			</div>
			<div class="col-md-4 item">
				<img src="assets/images/home/negocia_obligacones.svg" alt="Negociar obligaciones"
					title="Negociar obligaciones" />
				<div class="sub-title">
					<h3>Negocia GRATIS <br>con nuestros aliados</h3>
				</div>
				<p>Elige el plan de pago que mejor se ajuste a tu presupuesto para ponerte al día.</p>
			</div>
			<div class="col-md-4 item">
				<img src="assets/images/home/estar_al_dia.svg" alt="Al día con tus deudas"
					title="Al día con tus deudas" />
				<div class="sub-title">
					<h3>Ponte al día con <br>el pago de tus deudas</h3>
				</div>
				<p>Finaliza tu acuerdo de pago con la entidad y resuelve tus deudas de manera rápida y sin filas.</p>
			</div>
			<div class="col-12">
				<div class="advice">
					<p>Consejo: Entre menos tiempo elijas, mayor es el descuento.</p>
				</div>
			</div>
		</div>
	</div>
	<div class="container allies">
		<div class="row">
			<div class="col">
				<div class="title-sec">
					<h2>Aliados Ponte al día</h2>
					<hr>
				</div>
			</div>
		</div>
		<div class="row allies-item justify-content-center">
			<div *ngFor="let al of dataJsonAliados" class="col-6 col-md-2  align-self-center">
				<div class="allie-logo">
					<img src="{{al.url}}" alt="{{al.aliado}}" title="{{al.aliado}}" />
				</div>
			</div>
		</div>
	</div>
</div>
<!-- <app-sliders></app-sliders> -->
<div class="box-testimonials">
	<div class="container-fluid">
		<div class="container testimonials">
			<h2 class="title">Qué dicen nuestros usuarios</h2>
			<hr>
			<div class="box-all">
				<div class="box-people" *ngFor="let test of testimonials">
					<div class="icon-people">
						<img src="assets/images/home/testimonial.svg" alt="Testimonios" title="Testimonios" />
					</div>
					<div class="qualify d-none d-md-block">
						<i class="fa fa-star" aria-hidden="true" id="1star"
							[ngClass]="validarEstrella(1, test.qualify)"></i>
						<i class="fa fa-star" aria-hidden="true" id="2star"
							[ngClass]="validarEstrella(2, test.qualify)"></i>
						<i class="fa fa-star" aria-hidden="true" id="3star"
							[ngClass]="validarEstrella(3, test.qualify)"></i>
						<i class="fa fa-star" aria-hidden="true" id="4star"
							[ngClass]="validarEstrella(4, test.qualify)"></i>
						<i class="fa fa-star" aria-hidden="true" id="5star"
							[ngClass]="validarEstrella(5, test.qualify)"></i>
					</div>
					<p>{{test.description}}</p>
					<div class="d-flex">
						<p class="author">{{test.author}}</p>
						<div class="qualify d-flex d-md-none">
							<i class="fa fa-star" aria-hidden="true" id="1star"
								[ngClass]="validarEstrella(1, test.qualify)"></i>
							<i class="fa fa-star" aria-hidden="true" id="2star"
								[ngClass]="validarEstrella(2, test.qualify)"></i>
							<i class="fa fa-star" aria-hidden="true" id="3star"
								[ngClass]="validarEstrella(3, test.qualify)"></i>
							<i class="fa fa-star" aria-hidden="true" id="4star"
								[ngClass]="validarEstrella(4, test.qualify)"></i>
							<i class="fa fa-star" aria-hidden="true" id="5star"
								[ngClass]="validarEstrella(5, test.qualify)"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="container-fluid main-faq">
	<div class="container faq">
		<div class="row">
			<div class="col">
				<div class="title-sec">
					<h2>{{sesion.datos.faq?.titulo}}</h2>
					<hr>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-4 d-none d-md-block">
				<div class="img-faq">
					<img src="assets/images/all/preguntas_frecuentes.svg" alt="Preguntas frecuentes - Ponte al Día" />
				</div>
			</div>
			<div class="col-12 col-md-8 ">
				<div class="faq-pda">
					<div class="accordion" id="accordionExample">
						<div class="card">
							<div class="card-header" id="headingOne">
								<h2 class="mb-0">
									<button class="btn btn-link btn-block text-left btnCollapse" type="button"
										data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
										aria-controls="collapseOne" (click)="rotate(1)">
										¿Por qué no aparecen todas las deudas que tengo con las entidades financieras?
										<i class="fa fa-chevron-right" id="answer1" aria-hidden="true"></i>
									</button>
								</h2>
							</div>

							<div id="collapseOne" class="collapse" aria-labelledby="headingOne"
								data-parent="#accordionExample">
								<div class="card-body">
									<p>
										Sólo podrás visualizar las deudas que las entidades aliadas reportaron a Ponte
										al Día, esto no quiere decir que no tengas deudas con ninguna entidad, para lo
										que te sugerimos que consultes tu historia de crédito en <a
											href="https://www.midatacredito.com/">www.midatacredito.com</a>.
									</p>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" id="headingTwo">
								<h2 class="mb-0">
									<button class="btn btn-link btn-block text-left collapsed btnCollapse" type="button"
										data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
										aria-controls="collapseTwo" (click)="rotate(2)">
										¿Cuánto tarda en desaparecer el reporte negativo luego de que pague mi deuda?
										<i class="fa fa-chevron-right" id="answer2" aria-hidden="true"></i>
									</button>
								</h2>
							</div>
							<div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
								data-parent="#accordionExample">
								<div class="card-body">
									<p>Si la mora fue inferior a dos años, el informe durará el doble del tiempo de la
										mora. Por ejemplo, si la mora fue de 10 meses, el reporte negativo será visible
										durante 20 meses, y para moras mayores a dos años, se mantendrá el reporte
										durante cuatro años, que serán contados desde el momento en que la deuda sea
										puesta al día.</p>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header" id="headingThree">
								<h2 class="mb-0">
									<button class="btn btn-link btn-block text-left collapsed btnCollapse" type="button"
										data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
										aria-controls="collapseThree" (click)="rotate(3)">
										¿Cuál es la mejor opción de pago para mí?
										<i class="fa fa-chevron-right" id="answer3" aria-hidden="true"></i>
									</button>
								</h2>
							</div>
							<div id="collapseThree" class="collapse" aria-labelledby="headingThree"
								data-parent="#accordionExample">
								<div class="card-body">
									<p>Tú decides cuál es la mejor opción para ponerte al día con tu deuda. Puedes
										proponer una oferta de pago ajustada a tus necesidades, elige si quieres
										aumentar o disminuir el tiempo y el monto a pagar, entre menor sea el tiempo de
										pago, mayor va a ser el descuento, solo asegúrate de elegir una opción y un plan
										que puedas cumplir.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="midc">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-6 d-none d-sm-block ">
				<img src="assets/images/all/MiDC_Desktop.svg" alt="midc"/>
			</div>
			<div class="col-12 col-md-6">
				<img src="assets/images/all/go_freemium.svg" class="freemium-mobile d-block d-md-none" alt="freemium"/>
				<div class="sub-title">
					<h2>{{sesion.datos.midatacredito?.titulo}}</h2>
				</div>
				<p class="text-left">{{sesion.datos.midatacredito?.texto}}</p>
				<a (click)="enviarDataLayer('Ingresa gratis', 'midc')" class="btn btn-secundary btn-lg"
					id="midatacreditoHome">Ingresa GRATIS<i class="arrow right"></i></a>
			</div>
		</div>
	</div>
</div>